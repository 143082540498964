@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Dubai";
  src: local("Dubai"), local("Dubai"),
    url("./assets/fonts/DubaiW23-Light.eot") format("eot"),
    url("./assets/fonts/DubaiW23-Light.ttf") format("ttf"),
    url("./assets/fonts/DubaiW23-Light.woff") format("woff"),
    url("./assets/fonts/DubaiW23-Light.woff2") format("woff2");
  font-weight: 100;
  font-style: lighter;
}

@font-face {
  font-family: "Dubai";
  src: local("Dubai"), local("Dubai"),
    url("./assets/fonts/DubaiW23-Regular.eot") format("eot"),
    url("./assets/fonts/DubaiW23-Regular.ttf") format("ttf"),
    url("./assets/fonts/DubaiW23-Regular.woff") format("woff"),
    url("./assets/fonts/DubaiW23-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Dubai";
  src: local("Dubai"), local("Dubai"),
    url("./assets/fonts/DubaiW23-Bold.eot") format("eot"),
    url("./assets/fonts/DubaiW23-Bold.ttf") format("ttf"),
    url("./assets/fonts/DubaiW23-Bold.woff") format("woff"),
    url("./assets/fonts/DubaiW23-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: bold;
}

*,
html {
  direction: rtl;
  font-family: "Dubai", "Times New Roman", Times, serif;
}

@layer base {
  :root {
    --color-primary: 190 22 34;
    --color-secondary: 89 10 16;
    /* ... */
  }
}
